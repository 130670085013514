import React from 'react';
import HexagonAnimation from './HexagonAnimation';
import styles from './PurpleSection.module.scss';
import { Content } from '@layout';

export default function PurpleSection() {
  return (
    <div className={styles.wrapper}>
      <Content>
        <div className={styles.text}>
          <h2>Get a ground level view</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam placerat vitae risus at dictum.</p>
        </div>
        
        <HexagonAnimation />
      </Content>
      <div className={styles.bottomAngle} />
    </div>
  );
}